const routes = [
    {
        path: '/',
        name: 'main',
        component: () =>
            import(/* webpackChunkName: "index" */ "../../main/Main")
    },
    {
        path: '*',
        name: 'name',
        component: () =>
            import(/* webpackChunkName: "index" */ "../../main/Main")
    },
]


export default routes