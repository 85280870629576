import Vue from 'vue'
import App from './App.vue'
import router from './router'
import * as VueAos from 'vue-aos'
import AOS from 'aos'
import 'aos/dist/aos.css'

Vue.use(VueAos)
Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),
    mounted() {
        AOS.init()
    },
}).$mount('#app')
